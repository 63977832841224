export default {
  "fbApiKey": "AIzaSyAXZIQIOqQcQuxwMHW7LhKkIzRLiKUo53o",
  "vercelUrl": "notta-ptyq81o63-dylans-projects-d4b77235.vercel.app",
  "fbMessagingSenderId": "563341983644",
  "vercelGitRepoSlug": "Notta-Client",
  "vercelEnv": "production",
  "vercelGitCommitAuthorLogin": "dylankoevort",
  "fbAuthDomain": "notta-418218.firebaseapp.com",
  "vercelGitCommitMessage": "Merge pull request #18 from dylankoevort/develop\n\nMigration to Client-Side Firebase implementation, removed old server …",
  "fbProjectId": "notta-418218",
  "vercelGitCommitAuthorName": "Dylan Koevort",
  "clerkPublishableKey": "pk_live_Y2xlcmsubm90dGEuY28uemEk",
  "vercelGitProvider": "github",
  "vercelGitRepoOwner": "dylankoevort",
  "token": "123456",
  "vercelProjectProductionUrl": "notta.co.za",
  "env": "live",
  "fbAppId": "1:563341983644:web:7cd2b082150899df039259",
  "vercelGitRepoId": "763102553",
  "fbMeasurementId": "G-02CDD8ZNTH",
  "vercelBranchUrl": "notta-git-master-dylans-projects-d4b77235.vercel.app",
  "vercelGitPreviousSha": "",
  "vercelGitCommitRef": "master",
  "gateway": "https://notta.azurewebsites.net/api",
  "vercelGitCommitSha": "5b3ca50beea629d3d58528ecd6950fbd1f62b9db",
  "fbStorageBucket": "notta-418218.appspot.com",
  "vercelGitPullRequestId": ""
};
