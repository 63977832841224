import styled from "styled-components";

const StyledDashboard = styled.div`
  // height: 100%;
  // width: 100%;

  // h1 {
  //   margin: 0;
  // }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }
`;

export { StyledDashboard };
